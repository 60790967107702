import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { increaseTotalAccount, updateRevenueFromTransaction } from '../../services/statisticsSlice';

function TimeLine() {
    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = useState(false); // Quản lý trạng thái mở/đóng của timeline
    const [data, setData] = useState([]); // Dữ liệu timeline
    const [tick, setTick] = useState(0); // Thêm state cho bộ đếm
    const [showAllTransactions, setShowAllTransactions] = useState(true);
    const [ws, setWs] = useState(null);
    const wsRef = useRef(null); // Sử dụng useRef để lưu trữ tham chiếu đến WebSocket
    const userRole = localStorage.getItem('userRole'); // Lấy userRole từ localStorage

    useEffect(() => {
        function connect() {
            const ws = new WebSocket('wss://tvrewards.net/ws/timeline/');
            wsRef.current = ws; // Lưu WebSocket vào useRef

            ws.onopen = () => console.log('WebSocket Connected');

            ws.onmessage = (event) => {
                const message = JSON.parse(event.data);
                handleWebSocketMessage(message);
            };

            ws.onerror = (error) => console.error('WebSocket Error:', error);

            ws.onclose = () => {
                console.log('WebSocket Disconnected. Reconnecting...');
                setTimeout(connect, 5000); // Tự động kết nối lại sau 5 giây
            };
        }
        const interval = setInterval(() => {
            setTick(prev => prev + 1); // Cập nhật bộ đếm mỗi giây
        }, 1000);

        setWs(ws);


        connect();

        // Dọn dẹp khi component unmount
        return () => {
            if (wsRef.current) {
                wsRef.current.close();
            }
        };
    }, []);

    // Xử lý thông điệp từ WebSocket
    const handleWebSocketMessage = (message) => {
        // Xử lý thông điệp chung cho cả admin và người dùng thông thường
        switch (message.type) {
            case 'TIMELINE':
                setData(message.timeline);
                break;
            case 'NEW_MEMBER_REGISTERED':
                // Chỉ dispatch cho admin hoặc khi người dùng phù hợp
                if (userRole === 'admin' || checkMemberMatch(message.newmember)) {
                    dispatch(increaseTotalAccount());
                }
                break;
            case 'NEW_TRANSACTION':
                // Chỉ dispatch cho admin hoặc khi người dùng phù hợp
                if (userRole === 'admin' || checkMemberMatch(message.member)) {
                    if (message.coins) {
                        dispatch(updateRevenueFromTransaction(message.coins));
                    }
                }
                break;
            default:
                console.log(`Received an unknown message type: ${message.type}`);
        }
    };



    // Kiểm tra xem thông điệp có liên quan đến người dùng hiện tại không
    const checkMemberMatch = (memberName) => {
        const memname = localStorage.getItem('memname');
        return memname && memberName && memberName.toLowerCase().startsWith(memname.toLowerCase());
    };

    const filteredData = showAllTransactions ? data : data.filter(history => checkMemberMatch(history.history_username));

    // Toggle trạng thái mở/đóng của TimeLine
    const toggleTimeLine = () => setIsOpen(!isOpen);

    // Kiểm tra xem sự kiện có gần đây không
    const isRecent = (timeCreated) => moment().diff(moment(timeCreated), 'seconds') < 30;

    // Tính toán số lượng sự kiện gần đây
    const recentCount = data.filter(history => isRecent(history.history_timecreated)).length;

    // Ref cho container của timeline để thực hiện scroll
    const timelineContainerRef = useRef(null);

    // Cuộn đến đầu trang
    const scrollToTop = () => {
        timelineContainerRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
    };
    const handleShowAllTransactionsChange = () => setShowAllTransactions(!showAllTransactions);
    return (
        <div className={`fixed top-0 right-0 md:w-5/12 w-full h-screen transition-transform ${isOpen ? 'translate-x-0' : 'translate-x-[calc(100%-2rem)]'}`}>
            <div onClick={toggleTimeLine} className="fixed cursor-pointer rounded-s-2xl top-16 left-0 w-[2rem] h-[5rem] bg-blue-500 text-white shadow-md flex justify-center items-center text-exs hover:scale-105 transition duration-300">
                <div className="transform -rotate-90 select-none">TIMELINE</div>
                {recentCount > 0 && (
                    <div className="absolute top-0 left-0 mr-1 mt-1 w-4 h-4 bg-red-500 text-white text-exs rounded-full flex justify-center items-center">
                        {recentCount}
                    </div>
                )}
            </div>
            <div className='timeline-container h-full rounded-md bg-white ml-8 shadow-md mt-16 overflow-auto' ref={timelineContainerRef}>
            <div className='p-1 ml-3'>
                <label className="inline-flex items-center me-5 cursor-pointer">
                    <input type="checkbox" className="sr-only peer" checked={showAllTransactions} onChange={handleShowAllTransactionsChange} />
                    <div className={`relative w-8 h-4 rounded-full peer peer-focus:ring-1 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 ${showAllTransactions ? 'bg-blue-500' : 'bg-gray-200'} peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[5px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-3 after:w-3 after:transition-all dark:border-gray-600`}></div>
                    <span className="ms-3 text-xs font-semibold text-gray-900 dark:text-gray-300">{showAllTransactions ? 'All Transaction' : 'Only Me'}</span>
                </label>
            </div>
                <ol className="ml-5 relative border-s border-gray-200 dark:border-gray-700">
                   {filteredData.map((history, index) => (
                        <li className="mb-1 ms-5" key={history.id || index}> {/* Use a unique property like `history.id` or fallback to `index` */}
                            <div className={`absolute w-3 h-3  rounded-full mt-1.5 -start-1.5 border border-white dark:border-gray-900  ${isRecent(history.history_timecreated) ? 'bg-red-500' : 'bg-gray-200'} dark:bg-gray-700`}></div>
                            <time className="mb-1 text-exs font-normal leading-nonetext-gray-400 dark:text-gray-500">
                                {moment(history.history_timecreated).fromNow()}
                            </time>
                            <div>
                                <span className={`text-xs font-semibold  ${isRecent(history.history_timecreated) ? 'text-red-500' : 'text-blue-800'} dark:text-gray-400`}>{history.history_username}</span>

                                <span className="mb-1 text-exs font-normal text-gray-500 dark:text-gray-400"> đã hoàn thành </span>
                                <span className={`mb-1 text-xs font-semibold  ${isRecent(history.history_timecreated) ? 'text-red-500' : 'text-blue-800'} dark:text-gray-400`}>{history.history_offername} </span>
                                <span className="mb-1 text-exs font-normal text-gray-500 dark:text-gray-400">network </span>
                                <span className={`mb-1 text-xs font-semibold  ${isRecent(history.history_timecreated) ? 'text-red-500' : 'text-blue-800'} dark:text-gray-400`}>{history.history_network} </span>
                                <span className="mb-1 text-exs font-normal text-gray-500 dark:text-gray-400">nhận được </span>
                                <span className={`mb-1 text-xs font-semibold  ${isRecent(history.history_timecreated) ? 'text-red-500' : 'text-blue-800'} dark:text-gray-400`}>{history.history_coins} Coins </span>
                                <span className="mb-1 text-exs font-normal text-gray-500 dark:text-gray-400">lúc </span>
                                <span className={`mb-1 text-xs font-semibold  ${isRecent(history.history_timecreated) ? 'text-red-500' : 'text-blue-800'} dark:text-gray-400`}>{history.history_timecreated} </span>
                                <span className="mb-1 text-exs font-normal text-gray-500 dark:text-gray-400">tại site </span>
                                <span className={`mb-1 text-xs font-semibold  ${isRecent(history.history_timecreated) ? 'text-red-500' : 'text-blue-800'} dark:text-gray-400`}>{history.history_site}</span>
                            </div>
                        </li>
                    ))}
                </ol>
                <button onClick={scrollToTop} className="fixed bottom-5 right-5 bg-green-400 hover:bg-green-700 font-semibold text-black text-sm py-1 px-5 rounded-lg mt-100">
                    GO BACK TOP
                </button>
            </div>
        </div>
    );
}

export default TimeLine;
