import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from './axios'; // Adjust the path as necessary

// Initial state for the proxy slice
const initialState = {
  iProxyData: [], // Data for the iProxy
  fastproxyData: [], // Data for the Fastproxy
  loading: false, // Loading state for fetching data
  error: null, // Error state for fetching data
};

// Async thunk for fetching iProxy Data
export const fetchiProxyData = createAsyncThunk(
  'proxy/fetchiProxyData',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get('/api/v1/iproxy/'); // Adjust the API endpoint as necessary
      return response; // Assuming the response contains the data in its 'data' field
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

// Async thunk for fetching Fastproxy Data
export const fetchFastproxyData = createAsyncThunk(
  'proxy/fetchFastproxyData',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get('/api/v1/fastproxy/'); // Adjust the API endpoint as necessary
      return response; // Assuming the response contains the data in its 'data' field
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

// Slice definition
const proxySlice = createSlice({
  name: 'proxy',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Handling iProxy Data
      .addCase(fetchiProxyData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchiProxyData.fulfilled, (state, action) => {
        state.iProxyData = action.payload;
        state.loading = false;
      })
      .addCase(fetchiProxyData.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      // Handling Fastproxy Data
      .addCase(fetchFastproxyData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchFastproxyData.fulfilled, (state, action) => {
        state.fastproxyData = action.payload;
        state.loading = false;
      })
      .addCase(fetchFastproxyData.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      });
  },
});

export default proxySlice.reducer;
