import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchUsers } from '../../services/userSlice';
import axios from '../../services/axios';

function EmployeeManagement() {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.user.users);

  useEffect(() => {
    dispatch(fetchUsers());
  }, [dispatch]);

  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);

  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [newUser, setNewUser] = useState({
    username: '',
    email: '',
    password: '',
    user_name: '',
    is_active: false,
  });

  const handleDeleteUser = (userId) => {
    console.log(userId)
    setSelectedUserId(userId);
    setDeleteModalOpen(true);
  };

  const confirmDeleteUser = () => {
    if (selectedUserId) {
      console.log(selectedUserId)
      // Gửi yêu cầu xóa người dùng đến API
      axios
        .delete(`/api/users/`, { data: { id: selectedUserId } })
        .then((response) => {
          // Xử lý khi xóa thành công
          setDeleteModalOpen(false); // Đóng modal xác nhận xóa
          // Cập nhật danh sách người dùng sau khi xóa
          dispatch(fetchUsers());
        })
        .catch((error) => {
          // Xử lý khi có lỗi
          console.error('Error deleting user:', error);
        });
    }
  };


  const handleEditUser = (user) => {
    setSelectedUser(user);
    setEditModalOpen(true);
  };

  const saveEditedUser = () => {
    if (selectedUser) {
      // Create a JSON object with the "id" field and other updated user information
      const updatedUserData = {
        id: selectedUser.id,
        username: selectedUser.username, // Include the username field
        user_name: selectedUser.user_name,
        email: selectedUser.email,
        is_superuser: selectedUser.is_superuser,
        is_active: selectedUser.is_active,
      };
      axios
        .put(`/api/users/`, updatedUserData)
        .then((response) => {
          setEditModalOpen(false);
          dispatch(fetchUsers());
        })
        .catch((error) => {
          // Handle errors from the API request
          console.error('Error updating user:', error);
        });
    }
  };
  const openAddModal = () => {
    setNewUser({
      username: '',
      email: '',
      password: '',
      user_name: '',
      is_active: false,
    });
    setAddModalOpen(true);
  };

  const saveNewUser = () => {
    // Gửi yêu cầu đăng ký người dùng mới đến API
    axios
      .post('/api/users/', newUser)
      .then((response) => {
        // Xử lý khi đăng ký thành công
        setAddModalOpen(false); // Đóng modal đăng ký
        // Cập nhật danh sách người dùng sau khi thêm mới
        dispatch(fetchUsers());
      })
      .catch((error) => {
        // Xử lý khi có lỗi
        console.error('Error creating new user:', error);
      });
  };


  return (
    <div className="mt-12 mt-20">
      <div className="statistics">
        <div className="pl-5">
          <div className="font-semibold">
            Quản Lý Nhân Viên
          </div>
          <div className="text-xs">
            Sử dụng các hành động thêm/sửa/xóa để thao tác
          </div>
        </div>
        <div className="pl-5 mt-5">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-xs text-white font-bold py-2 px-4 rounded"
            onClick={openAddModal}
          >
            + Add Employee
          </button>
        </div>
        <div>
          <div className="charttrend mt-5">
            <div className='bg-white rounded-sm justify-center items-center w-full '>
              <table className='w-full divide-y divide-gray-200'>
                <thead>
                  <tr>
                    <th className=" px-3 py-3 text-center text-xs font-bold text-gray-500 uppercase tracking-wider">
                      Username
                    </th>
                    <th className=" px-3 py-3 text-center text-xs font-bold text-gray-500 uppercase tracking-wider">
                      Employee Name
                    </th>
                    <th className=" px-3 py-3 text-center text-xs font-bold text-gray-500 uppercase tracking-wider">
                      Email
                    </th>
                    
                    <th className="px-3 py-3 text-center text-xs font-bold text-gray-500 uppercase tracking-wider">
                      Is Superadmin
                    </th>
                    <th className="px-3 py-3 text-center text-xs font-bold text-gray-500 uppercase tracking-wider">
                      Is Active
                    </th>
                    <th className="px-3 py-3 text-center text-xs font-bold text-gray-500 uppercase tracking-wider">Action</th>
                  </tr>
                </thead>
                <tbody className='bg-white divide-y divide-gray-200'>
                  {users.map((user) => (
                    <tr key={user.id} className="border-b border-gray-200">
                      <td className="py-2  whitespace-nowrap text-sm text-center text-gray-500">
                        {user.username}
                      </td>
                      <td className="py-2  whitespace-nowrap text-sm text-center text-gray-500">
                        {user.user_name}
                      </td>
                      <td className="py-2  whitespace-nowrap text-sm text-center text-gray-500">
                        {user.email}
                      </td>
                      <td className="py-2  whitespace-nowrap text-sm text-center text-gray-500">
                        {user.is_superuser ? 'Yes' : 'No'}
                      </td>
                      <td className="py-2  whitespace-nowrap text-sm text-center text-gray-500">
                        {user.is_active ? 'Yes' : 'No'}
                      </td>
                      <td className="flex justify-center gap-5 py-2  whitespace-nowrap text-sm text-center text-gray-500">
                        <button
                          className="text-indigo-600 hover:text-indigo-900"
                          onClick={() => handleEditUser(user)}
                        >
                          <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">

                            <g>
                              <title>Layer 1</title>
                              <path id="svg_1" fill="#6563ff" d="m19,10.0058a1,1 0 0 0 -1,1l0,6a1,1 0 0 1 -1,1l-14,0a1,1 0 0 1 -1,-1l0,-14a1,1 0 0 1 1,-1l6,0a1,1 0 0 0 0,-2l-6,0a3,3 0 0 0 -3,3l0,14a3,3 0 0 0 3,3l14,0a3,3 0 0 0 3,-3l0,-6a1,1 0 0 0 -1,-1zm-15,0.76l0,4.24a1,1 0 0 0 1,1l4.24,0a1,1 0 0 0 0.71,-0.29l6.92,-6.93l0,0l2.84,-2.78a1,1 0 0 0 0,-1.42l-4.24,-4.29a1,1 0 0 0 -1.42,0l-2.82,2.83l0,0l-6.94,6.93a1,1 0 0 0 -0.29,0.71zm10.76,-8.35l2.83,2.83l-1.42,1.42l-2.83,-2.83l1.42,-1.42zm-8.76,8.76l5.93,-5.93l2.83,2.83l-5.93,5.93l-2.83,0l0,-2.83z" />
                            </g>
                          </svg>
                        </button>
                        <button
                          onClick={() => handleDeleteUser(user.id)}
                          className="ml-2"
                          style={{ background: 'none', border: 'none', cursor: 'pointer' }}
                        >
                          <svg width="16" height="18" clip-rule="evenodd" fillRule="evenodd" imageRendering="optimizeQuality" textRendering="geometricPrecision" shapeRendering="geometricPrecision">

                            <g>
                              <title>Layer 1</title>
                              <path stroke="null" id="svg_1" d="m1.69895,4.91684l12.7014,0c0.60912,0 1.04632,0.34316 0.98526,0.98842l-1.07825,11.12632c-0.04316,0.46877 -0.31544,0.92702 -0.88772,0.92702l-10.74105,0c-0.47825,-0.02561 -0.83298,-0.34211 -0.88035,-0.84702l-1.06877,-11.18211c-0.06105,-0.64491 0.34211,-1.01263 0.96947,-1.01263zm-0.83965,-3.57825l4.78842,0l0,-0.35263c0,-0.5593 0.35789,-0.98596 0.94807,-0.98596l2.85193,0c0.60702,0 0.97018,0.41298 0.97018,0.98281l0,0.35579l4.86526,0c0.02,0 0.03895,0.00246 0.05895,0.00456c0.35895,0.03263 0.63684,0.31649 0.65579,0.67439c0.00105,0.02772 0.00211,0.04877 0.00211,0.07614l0,1.50035c0,0.21018 -0.16596,0.38211 -0.37263,0.39263c-0.01895,0 -0.03825,0.00105 -0.05719,0.00105l-15.17649,0c-0.20772,0 -0.37789,-0.1614 -0.39263,-0.36421c0,-0.02526 -0.00105,-0.05158 -0.00105,-0.07825l0,-1.38596c0,-0.38351 0.14772,-0.72667 0.59018,-0.80772c0.08877,-0.01579 0.1786,-0.01298 0.26912,-0.01298zm2.93965,7.31158c-0.01789,-0.35684 0.28807,-0.6607 0.68526,-0.67754c0.39684,-0.01719 0.73474,0.25965 0.75263,0.61649l0.30526,5.63579c0.01789,0.35719 -0.28842,0.66105 -0.68526,0.67789c-0.39719,0.01684 -0.73474,-0.25965 -0.75298,-0.61649l-0.30491,-5.63614zm7.07719,-0.06105c0.01789,-0.35684 0.35579,-0.63368 0.75263,-0.61649c0.39719,0.01684 0.70316,0.3207 0.68526,0.67754l-0.30386,5.63614c-0.01825,0.35684 -0.35579,0.63333 -0.75298,0.61649c-0.39684,-0.01684 -0.70316,-0.3207 -0.68526,-0.67789l0.30421,-5.63579zm-3.61193,0.03053c0,-0.35895 0.32386,-0.65018 0.72211,-0.65018c0.39789,0 0.72211,0.29123 0.72211,0.65018l0,5.63614c0,0.35895 -0.32421,0.65018 -0.72211,0.65018c-0.39825,0 -0.72211,-0.29123 -0.72211,-0.65018l0,-5.63614z" fill="#FD3B3B" />
                            </g>
                          </svg>
                        </button>

                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {/* Modal Xác nhận Xóa */}
      {isDeleteModalOpen && (
        <div class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal title" role="dialog" aria-modal="true">
          <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div class="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

            <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

            <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div class="sm:flex sm:items-start">
                  <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <svg class="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"></path>
                    </svg>
                  </div>

                  <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">Employee Delete</h3>

                    <div class="mt-2">
                      <p class="text-sm text-gray-500">
                        Bạn có chắc chắn muốn nhân viên này khỏi hệ thống không?
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm" onClick={confirmDeleteUser}>Yes</button>
                <button type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm" onClick={() => setDeleteModalOpen(false)}>No</button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Modal Chỉnh sửa thông tin người dùng */}
      {isEditModalOpen && (
        <div class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal title" role="dialog" aria-modal="true">
          <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div class="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

            <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal title" role="dialog" aria-modal="true">
              <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div class="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

                <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

                <div className="fixed inset-0 flex items-center justify-center z-50">
                  <div className="bg-white dark:bg-gray-700 rounded-lg shadow-lg p-4 md:max-w-md w-full">
                    <div className="flex justify-end">

                    </div>
                    <form className="space-y-6 px-6 lg:px-8 pb-4 sm:pb-6 xl:pb-8" >
                      <h3 className="text-xl font-medium text-gray-900 dark:text-white">Edit User</h3>
                      <div>
                        <label className="text-sm font-medium text-gray-900 block mb-2 dark:text-gray-300">Username</label>
                        <input
                          type="text"
                          id="editUsername"

                          value={selectedUser.username}
                          onChange={(e) => setSelectedUser({ ...selectedUser, username: e.target.value })}
                          className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                        />
                      </div>
                      <div>
                        <label className="text-sm font-medium text-gray-900 block mb-2 dark:text-gray-300">Employee Name</label>
                        <input
                          type="text"
                          id="editUserName"
                          value={selectedUser.user_name}
                          onChange={(e) => setSelectedUser({ ...selectedUser, user_name: e.target.value })}
                          className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                          required
                        />
                      </div>




                      <div>
                        <label className="text-sm font-medium text-gray-900 block mb-2 dark:text-gray-300">Email</label>
                        <input

                          type="text"
                          id="editEmail"
                          value={selectedUser.email}
                          onChange={(e) => setSelectedUser({ ...selectedUser, email: e.target.value })}
                          className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                          required
                        />
                      </div>



                      <div>
                        <label className="text-sm font-medium text-gray-900 block mb-2 dark:text-gray-300">Is Superadmin</label>
                        <input

                          type="checkbox"
                          id="editIsSuperadmin"
                          checked={selectedUser.is_superuser}
                          onChange={() => setSelectedUser({ ...selectedUser, is_superuser: !selectedUser.is_superuser })}
                          className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                        
                        />
                      </div>


                      <div>
                        <label className="text-sm font-medium text-gray-900 block mb-2 dark:text-gray-300">Is Active</label>
                        <input
                          type="checkbox"
                          id="editIsActive"
                          checked={selectedUser.is_active}
                          onChange={() => setSelectedUser({ ...selectedUser, is_active: !selectedUser.is_active })}
                          className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                          required
                        />
                      </div>
                      <div className='grid gap-y-5 gap-x-6 md:grid-cols-2 xl:grid-cols-2 p-1'>
                        <button

                          className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" onClick={saveEditedUser}
                        >
                          Save Edited
                        </button>
                        <button type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm" onClick={() => setEditModalOpen(false)}>No</button>
                      </div>

                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {isAddModalOpen && (
        <div class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal title" role="dialog" aria-modal="true">
          <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div class="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

            <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

            <div className="fixed inset-0 flex items-center justify-center z-50">
              <div className="bg-white dark:bg-gray-700 rounded-lg shadow-lg p-4 md:max-w-md w-full">
                <div className="flex justify-end">

                </div>
                <form className="space-y-6 px-6 lg:px-8 pb-4 sm:pb-6 xl:pb-8">
                  <h3 className="text-xl font-medium text-gray-900 dark:text-white">Add NewEmployee Management</h3>
                  <div>
                    <label className="text-sm font-medium text-gray-900 block mb-2 dark:text-gray-300"> Username</label>
                    <input
                      type="text"
                      id="addUsername"
                      value={newUser.username}
                      onChange={(e) => setNewUser({ ...newUser, username: e.target.value })}
                      className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                    />
                  </div>
                  <div>
                    <label className="text-sm font-medium text-gray-900 block mb-2 dark:text-gray-300">Email</label>
                    <input
                      type="text"
                      id="addEmail"
                      value={newUser.email}
                      onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
                      className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                      required
                    />
                  </div>


                  <div>
                    <label className="text-sm font-medium text-gray-900 block mb-2 dark:text-gray-300">Password</label>
                    <input
                      type="password"
                      id="addPassword"
                      value={newUser.password}
                      onChange={(e) => setNewUser({ ...newUser, password: e.target.value })}
                      className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                      required
                    />
                  </div>

                  <div>
                    <label className="text-sm font-medium text-gray-900 block mb-2 dark:text-gray-300"> Employee Name</label>
                    <input
                      type="text"
                      id="addUser_name"
                      value={newUser.user_name}
                      onChange={(e) => setNewUser({ ...newUser, user_name: e.target.value })}
                      className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                      required
                    />
                  </div>

                 
                  <div>
                    <label className="text-sm font-medium text-gray-900 block mb-2 dark:text-gray-300"> Is Active</label>
                    <input
                      type="checkbox"
                      id="addIsActive"

                      checked={newUser.is_active}
                      onChange={() => setNewUser({ ...newUser, is_active: !newUser.is_active })}
                      className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                      required
                    />
                  </div>


                  <button

                    className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" onClick={saveNewUser}
                  >
                    Save Edited
                  </button>
                  <button

                    className="w-full bg-gray-300 text-gray-700 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" onClick={() => setAddModalOpen(false)}
                  >
                    Cancel
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default EmployeeManagement;
