import React, { useState } from 'react';


function AddSiteModal({ isOpen, onClose, onAddSite }) {
  const [siteData, setSiteData] = useState({ site_name: '', site_ratio: '' });

  const handleInputChange = (e) => {
      const { name, value } = e.target;
      setSiteData({ ...siteData, [name]: value });
  };

  const submitAddSite = () => {
      onAddSite(siteData);
      setSiteData({ site_name: '', site_ratio: '' }); // Reset form
  };
  
  return (
    <div className={`modal ${isOpen ? 'show' : ''}`}>
    <div className={`fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center ${isOpen ? 'block' : 'hidden'}`}>
      <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
        <div className="flex justify-between items-center mb-4">
          <h4 className="text-lg font-bold">Add Site</h4>
          <button onClick={onClose} className="text-black bg-transparent hover:bg-gray-200 hover:text-black rounded-lg text-sm p-1.5 ml-auto inline-flex items-center">
                        <svg className="w-5 h-5" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                            <path d="M6 18L18 6M6 6l12 12"></path>
                        </svg>
                    </button>
        </div>
        <form>
          {/* Form fields for site_name and site_ratio */}
          <div className="mb-4">
            <label htmlFor="site_name" className="block text-gray-700 text-sm font-bold mb-2">
              Site Name:
            </label>
            <input
              type="text"
              name="site_name"
              value={siteData.site_name}
              onChange={handleInputChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="mb-6">
            <label htmlFor="site_ratio" className="block text-gray-700 text-sm font-bold mb-2">
              Site Ratio:
            </label>
            <input
              type="text"
              name="site_ratio"
                value={siteData.site_ratio}
                onChange={handleInputChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="flex items-center justify-between">
            <button
              type="button"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              onClick={submitAddSite}
            >
              Add Site
            </button>
          </div>
        </form>
      </div>
    </div>
    </div>
  );
}

export default AddSiteModal;
