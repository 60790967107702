import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from './axios'; // Make sure this path is correct
import moment from 'moment-timezone';

// Initial state
const initialState = {
  historyTransactionData: [],
  membersiteData: [],
  loading: {
    historyTransaction: false,
    membersite: false,
  },
  error: {
    historyTransaction: null,
    membersite: null,
  },
};
// Async thunk for history transaction data
export const fetchHistoryTransactionData = createAsyncThunk(
  'apps/fetchHistoryTransactionData',
  async ({ startDate, endDate }, { rejectWithValue }) => {
    try {
      const endOfDay = moment(endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss');
      const response = await axios.get(`/api/v1/history/?start_date=${startDate}&end_date=${endOfDay}`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

// Async thunk for member site data
export const fetchMemberSiteData = createAsyncThunk(
  'apps/fetchMemberSiteData',
  async ({ startDate, endDate }, { rejectWithValue }) => {
    try {
      const endOfDay = moment(endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss');
      const response = await axios.get(`/api/v1/membersite/?start_date=${startDate}&end_date=${endOfDay}`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

// Slice definition
const appsSlice = createSlice({
  name: 'apps',
  initialState,
  reducers: {
    // You can add reducers here if needed
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchHistoryTransactionData.pending, (state) => {
        state.loading.historyTransaction = true;
      })
      .addCase(fetchHistoryTransactionData.fulfilled, (state, action) => {
        state.historyTransactionData = action.payload;
        state.loading.historyTransaction = false;
      })
      .addCase(fetchHistoryTransactionData.rejected, (state, action) => {
        state.error.historyTransaction = action.payload;
        state.loading.historyTransaction = false;
      })
      .addCase(fetchMemberSiteData.pending, (state) => {
        state.loading.membersite = true;
      })
      .addCase(fetchMemberSiteData.fulfilled, (state, action) => {
        state.membersiteData = action.payload;
        state.loading.membersite = false;
      })
      .addCase(fetchMemberSiteData.rejected, (state, action) => {
        state.error.membersite = action.payload;
        state.loading.membersite = false;
      });
  },
});

export default appsSlice.reducer;
