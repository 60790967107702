import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { useSelector } from 'react-redux';
import NetworkTableData from './networktabledata';

const NetworkTrendChart = () => {
  const chartData = useSelector(state => state.charts.networkTrendChartData);
  const COLORS = ['#E57373', '#3dbd37', '#8E44AD', '#58D68D', '#00FFFF', '#3dbd37', '#DC7633', '#581845', '#21618C', '#34495E'];

const chartOptions = {
  chart: {
    type: 'donut',
    width: '100%'
  },
  labels: chartData.map((entry) => entry.name),
  legend: {
    show: true, // This line will hide the legend
  },
  colors: COLORS,
  

};

  const chartSeries = chartData.map((entry) => entry.data);

  return (
    <div className='bg-white rounded-sm justify-center items-center w-full '>
      <div className='border-b p-1 border-gray-200 p-3 text-sm font-semibold'>
        Tương Quan Network
      </div>
      <ReactApexChart
        className='bg-white rounded-lg justify-center items-center  w-full'
        options={chartOptions}
        series={chartSeries}
        type='donut'
        height={350}
    
      />
      <NetworkTableData />
    </div>
  );
};

export default NetworkTrendChart;
