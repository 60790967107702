import { createSlice } from '@reduxjs/toolkit';

export const dateRangeSlice = createSlice({
    name: 'dateRange',
    initialState: {
        startDate: null,
        endDate: null
    },
    reducers: {
        setDateRange: (state, action) => {
            state.startDate = action.payload.startDate;
            state.endDate = action.payload.endDate;
        }
    }
});

// Export actions
export const { setDateRange } = dateRangeSlice.actions;

// Export reducer
export default dateRangeSlice.reducer;