import React, { useState, useEffect } from 'react';

const EditNetworkModal = ({ network, onClose, onSave }) => {
    const [editedNetwork, setEditedNetwork] = useState(network);

    useEffect(() => {
        if (network) {
            setEditedNetwork(network);
        }
    }, [network]);

    const handleChange = (e) => {
        setEditedNetwork({ ...editedNetwork, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSave(editedNetwork);
    };

    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center">
            <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
                <div className="flex justify-between items-center mb-4">
                    <h4 className="text-lg font-bold">Edit Network</h4>
                    <button onClick={onClose} className="text-black bg-transparent hover:bg-gray-200 hover:text-black rounded-lg text-sm p-1.5 ml-auto inline-flex items-center">
                        <svg className="w-5 h-5" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                            <path d="M6 18L18 6M6 6l12 12"></path>
                        </svg>
                    </button>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label htmlFor="network_name" className="block text-gray-700 text-sm font-bold mb-2">Network Name:</label>
                        <input
                            type="text"
                            name="network_name"
                            value={editedNetwork.network_name || ''}
                            onChange={handleChange}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        />
                    </div>
                    {/* Thêm các trường dữ liệu khác nếu cần */}
                    <div className="flex items-center justify-between">
                        <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                            Save
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EditNetworkModal;
