import TableClickerDevice from "./clicker/tableclickerdevice";

function ClickerSetting() {
    return (
        <div className="mt-12 mt-20">
            <div className="statistics">
                <div className="pl-5">
                    <div className="font-semibold">
                        Quản Lý Click
                    </div>
                    <div className="text-xs">
                        Sử dụng các hành động thêm/sửa/xóa để thao tác
                    </div>
                </div>
                <div className="max-w-full overflow-x-auto">
                <div className="pl-5 max-w-full overflow-x-auto">
                <div className=''>
                        {/* Nếu muốn TableClickerDevice chiếm 50% chiều rộng ở màn hình medium trở lên */}
                        <TableClickerDevice />
                        </div>
                    </div>
                    {/* Có thể thêm các thành phần khác ở đây nếu bạn muốn chúng hiển thị cùng hàng với TableClickerDevice */}
                </div>
            </div>
        </div>
    );
}
export default ClickerSetting;