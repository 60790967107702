import React, { useState, useEffect } from 'react';


const Slider = ({ sliderWidth, sliderHeight , onSlideChange}) => {
    const [slider] = useState([
        <div className="slider-feature">
            <div className="feature-title">MANAGE DIVERSITY
                CAPTURE EVERYOPPORTUNITY</div>
            <div className="feature-detail">Our system allows you to effortlessly organize, track, and optimize advertising campaigns, from CPC and CPA to CPI, offering you maximum flexibility.</div>
        </div>,
        <div className="slider-feature">
            <div className="feature-title">User-Friendly Interface – Intuitive Experience</div>
            <div className="feature-detail">SaviartMedia's user-friendly interface ensures a smooth and easy management experience. Anyone can become an advertising expert with our intuitive tools and detailed reporting.</div>
        </div>,
        <div className="slider-feature">
            <div className="feature-title">Automated Optimization – Unmatched Efficiency</div>
            <div className="feature-detail">Utilizing AI technology, SaviartMedia automatically optimizes your offer walls to enhance performance and ROI. Our system learns from data to make intelligent decisions, keeping you ahead of the competition.</div>
        </div>,
        <div className="slider-feature">
            <div className="feature-title">In-Depth Data Analysis – Make Informed Decisions</div>
            <div className="feature-detail">Gathering and analyzing detailed data, SaviartMedia offers deep insights into campaign performance. Make accurate decisions to enhance advertising effectiveness.</div>
        </div>,
        <div className="slider-feature">
            <div className="feature-title">"Effortless Integration – Streamline Your Workflow</div>
            <div className="feature-detail">SaviartMedia is engineered for ease of integration. Seamlessly connect with existing systems and platforms, enhancing your workflow without any disruption.</div>
        </div>,

        // Thêm thêm các div tương tự cho các slide khác
    ]);



    const [activeIndex, setActiveIndex] = useState(1);


    useEffect(() => {
        const interval = setInterval(() => {
          const nextIndex = activeIndex === slider.length ? 1 : activeIndex + 1;
          setActiveIndex(nextIndex);
          onSlideChange(nextIndex);
        }, 5000); // Thay đổi slide mỗi 5 giây
    
        return () => clearInterval(interval);
      }, [activeIndex, slider.length, onSlideChange]);
    
      const style = {
        width: sliderWidth,
        height: sliderHeight
      };

    return (
        <div className='slide-home-container'>
            <div className="slider-wrapper">
                <div className="slider">
                    {slider.map((item, index) => (
                        <div
                            key={index}
                            style={style}
                            className={index + 1 === activeIndex ? "slider-item" : "hide"}
                        >
                            {item}
                        </div>
                    ))}
                </div>
            </div>

            <div className="indicators-wrapper">
                <div className="indicators">
                    {slider.map((item, index) => (
                        <div
                            key={index}
                            className={index + 1 === activeIndex ? "active-indicator" : ""}
                          
                        >
                            {index + 1}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Slider;