// chartsSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from './axios'; // Đảm bảo rằng đường dẫn này chính xác
import moment from 'moment-timezone';

const initialState = {
  workTrendChartData: [],
  sitesTrendChartData: [],
  networkTrendChartData: [], // Thêm networkTrendChartData vào đây
  loading: {
    workTrendChart: false,
    sitesTrendChart: false,
    networkTrendChart: false, // Thêm networkTrendChart vào đây
  },
  error: {
    workTrendChart: null,
    sitesTrendChart: null,
    networkTrendChart: null, // Thêm networkTrendChart vào đây
  },
};

// Hàm fetch dữ liệu cho biểu đồ WorkTrendChart
export const fetchWorkTrendChartData = createAsyncThunk(
  'charts/fetchWorkTrendChartData',
  async ({ startDate, endDate }, { rejectWithValue }) => {
    try {
      const endOfDay = moment(endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss');
      const response = await axios.get(`/api/v1/workchart-data/?start_date=${startDate}&end_date=${endOfDay}`);
      return response; // Giả sử API trả về một mảng dữ liệu cho biểu đồ
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

// Hàm fetch dữ liệu cho biểu đồ SiteTrendChart
export const fetchSitesTrendChartData = createAsyncThunk(
  'charts/fetchSitesTrendChartData',
  async ({ startDate, endDate }, { rejectWithValue }) => {
    try {
      const endOfDay = moment(endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss');
      const response = await axios.get(`/api/v1/siteschart-data/?start_date=${startDate}&end_date=${endOfDay}`);
      return response; // Giả sử API trả về một mảng dữ liệu cho biểu đồ
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

// Hàm fetch dữ liệu cho biểu đồ NetworkTrendChart
export const fetchNetworkTrendChartData = createAsyncThunk(
  'charts/fetchNetworkTrendChartData',
  async ({ startDate, endDate }, { rejectWithValue }) => {
    try {
      const endOfDay = moment(endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss');
      const response = await axios.get(`/api/v1/networkchart-data/?start_date=${startDate}&end_date=${endOfDay}`);
      return response; // Giả sử API trả về một mảng dữ liệu cho biểu đồ
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

// Định nghĩa selector để lấy dữ liệu từ slice cho WorkTrendChart
export const selectWorkTrendChartData = (state) => state.charts.workTrendChartData;

// Định nghĩa selector để lấy dữ liệu từ slice cho SiteTrendChart
export const selectSitesTrendChartData = (state) => state.charts.sitesTrendChartData;

// Định nghĩa selector để lấy dữ liệu từ slice cho NetworkTrendChart
export const selectNetworkTrendChartData = (state) => state.charts.networkTrendChartData;

const chartsSlice = createSlice({
  name: 'charts',
  initialState,
  reducers: {
    // Bạn có thể thêm các reducers thông thường tại đây nếu cần
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchWorkTrendChartData.pending, (state) => {
        state.loading.workTrendChart = true;
      })
      .addCase(fetchWorkTrendChartData.fulfilled, (state, action) => {
        state.workTrendChartData = action.payload;
        state.loading.workTrendChart = false;
      })
      .addCase(fetchWorkTrendChartData.rejected, (state, action) => {
        state.error.workTrendChart = action.payload;
        state.loading.workTrendChart = false;
      })
      .addCase(fetchSitesTrendChartData.pending, (state) => {
        state.loading.sitesTrendChart = true;
      })
      .addCase(fetchSitesTrendChartData.fulfilled, (state, action) => {
        state.sitesTrendChartData = action.payload;
        state.loading.sitesTrendChart = false;
      })
      .addCase(fetchSitesTrendChartData.rejected, (state, action) => {
        state.error.sitesTrendChart = action.payload;
        state.loading.sitesTrendChart = false;
      })
      .addCase(fetchNetworkTrendChartData.pending, (state) => {
        state.loading.networkTrendChart = true;
      })
      .addCase(fetchNetworkTrendChartData.fulfilled, (state, action) => {
        state.networkTrendChartData = action.payload;
        state.loading.networkTrendChart = false;
      })
      .addCase(fetchNetworkTrendChartData.rejected, (state, action) => {
        state.error.networkTrendChart = action.payload;
        state.loading.networkTrendChart = false;
      });
  },
});

export default chartsSlice.reducer;
