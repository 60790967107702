import React, { useState } from 'react';

function AddNetworkModal({ isOpen, onClose, onAddNetwork }) {
    const [networkData, setNetworkData] = useState({ network_name: '' });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNetworkData({ ...networkData, [name]: value });
    };

    const submitAddNetwork = () => {
        onAddNetwork(networkData);
        setNetworkData({ network_name: '' }); // Reset form
    };

    return (
        <div className={`modal ${isOpen ? 'show' : ''}`}>
            <div className={`fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center ${isOpen ? 'block' : 'hidden'}`}>
                <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
                    <div className="flex justify-between items-center mb-4">
                        <h4 className="text-lg font-bold">Add Network</h4>
                        <button onClick={onClose} className="text-black bg-transparent hover:bg-gray-200 hover:text-black rounded-lg text-sm p-1.5 ml-auto inline-flex items-center">
                        <svg className="w-5 h-5" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                            <path d="M6 18L18 6M6 6l12 12"></path>
                        </svg>
                        </button>
                    </div>
                    <form>
                        <div className="mb-4">
                            <label htmlFor="network_name" className="block text-gray-700 text-sm font-bold mb-2">
                                Network Name:
                            </label>
                            <input
                                type="text"
                                name="network_name"
                                value={networkData.network_name}
                                onChange={handleInputChange}
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                required
                            />
                        </div>
                        {/* Additional fields if needed */}
                        <div className="flex items-center justify-between">
                            <button
                                type="button"
                                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                                onClick={submitAddNetwork}
                            >
                                Add Network
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default AddNetworkModal;
