import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from './axios'; // Điều chỉnh đường dẫn nếu cần

// Trạng thái ban đầu cho clicker slice
const initialState = {
  clickerDeviceData: [], // Dữ liệu cho các thiết bị
  loading: false, // Trạng thái loading khi tải dữ liệu
  error: null, // Trạng thái lỗi khi tải dữ liệu
};

// Async thunk để lấy dữ liệu thiết bị
export const fetchClickerDeviceData = createAsyncThunk(
  'clicker/fetchClickerDeviceData',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get('/api/clickdevices/'); // Điều chỉnh đường dẫn API nếu cần
      return response; // Giả định rằng phản hồi chứa dữ liệu trong trường 'data'
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

// Định nghĩa slice
const clickerSlice = createSlice({
  name: 'clicker',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Xử lý dữ liệu thiết bị
      .addCase(fetchClickerDeviceData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchClickerDeviceData.fulfilled, (state, action) => {
        state.clickerDeviceData = action.payload;
        state.loading = false;
      })
      .addCase(fetchClickerDeviceData.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      });
  },
});

export default clickerSlice.reducer;
