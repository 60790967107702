import axios from "../services/axios";

const fetchAuth = (username, password) => {
    return axios.post("/api/token/", {
        username,
        password
    });
}

export { fetchAuth };


