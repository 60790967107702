import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchClickerDeviceData } from '../../../services/clickerSlice';
import moment from 'moment';
import axios from '../../../services/axios';
import { GridLoader } from 'react-spinners';

function TableClickerDevice() {
    const dispatch = useDispatch();
    const { clickerDeviceData, loading, error } = useSelector(state => state.clicker);
    const [devicesState, setDevicesState] = useState({});
    const [openedPopups, setOpenedPopups] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [newDevice, setNewDevice] = useState({ site: '', device_name: '' });


    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await dispatch(fetchClickerDeviceData()).unwrap();

                setDevicesState(data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
        const intervalId = setInterval(fetchData, 300000);
        return () => clearInterval(intervalId);
    }, [dispatch]);

    useEffect(() => {
        setDevicesState(clickerDeviceData);
    }, [clickerDeviceData]);

    useEffect(() => {
        if (clickerDeviceData) {
            Object.entries(clickerDeviceData).forEach(([site, devices]) => {
                devices.forEach(device => {
                    const isOnline = moment().diff(moment(device.last_request_time), 'minutes') < 15;
                    if (!isOnline && !openedPopups[device.id]) {
                        // Thêm device.id vào tên popup để mỗi popup có một tên duy nhất
                        const popupName = `popup_${device.id}`;

                        const popup = window.open(
                          `http://${device.device_localip}:8080/control/start_playing?path=ClickerSystem/run.lua`,
                          popupName,
                          'height=200,width=200'
                        );
                        setOpenedPopups(prev => ({ ...prev, [device.id]: popup }));
                    }
                });
            });
        }
    }, [clickerDeviceData, openedPopups]);

    const handleInputChange = (site, deviceId, field, value) => {
        setDevicesState(prevState => ({
            ...prevState,
            [site]: prevState[site].map(device =>
                device.id === deviceId ? { ...device, [field]: value } : device
            )
        }));
    };

    const deleteDeviceData = async (site, deviceId) => {
        try {
            const response = await axios.delete(`api/clickdevices/${deviceId}/`);
            if (response === 'deleted') {

                setDevicesState(prevState => {
                    // Creating a new object for the state
                    const newState = { ...prevState };
                    // Updating the specific site's device list
                    newState[site] = newState[site].filter(device => device.id !== deviceId);

                    // Returning the new state object
                    return newState;
                });
            }
        } catch (error) {
            console.error('Failed to delete device:', error);
        }
    };

    const updateDeviceData = async (device) => {
        try {

            const response = await axios.put(`api/clickdevices/${device.id}/`, device);

            if (response.status === 200) {
                // Handle successful update logic here
            }
        } catch (error) {
            console.error('Failed to update device data:', error);
        }
    };


    const handleSaveNewDevice = async () => {
        try {
            // Chuyển đổi giá trị site và device_name thành chữ thường
            const lowercaseDevice = {
                site: newDevice.site.toLowerCase(),
                device_name: newDevice.device_name.toLowerCase(),
            };
    
            const response = await axios.post('api/clickdevices/', lowercaseDevice);
        

            if (response && response.id) {
                console.log('Device added successfully:', response);
                setDevicesState(prev => {
                    const updatedDevices = { ...prev };
                    const newDevices = updatedDevices[lowercaseDevice.site] ? [...updatedDevices[lowercaseDevice.site]] : [];
                    newDevices.push(response); // Sử dụng response.data để thêm thiết bị mới
                    updatedDevices[lowercaseDevice.site] = newDevices;
                    return updatedDevices;
                });    
                setIsModalOpen(false);
            }
        } catch (error) {
            console.error('Failed to add device:', error);
        }
    };









    if (loading) {
        return  (   
            <div className='w-full flex justify-center items-center'>
             <div className='rounded-sm justify-center items-center'>
               
                    <GridLoader color={"#123abc"} />
              
             </div>
        </div>
        )
    }

    if (error) {
        return <p className="text-red-500 text-center">Error loading data: {error.message}</p>;
    }




    return (
        <div className="mt-5">
            <button
                className="bg-blue-500 hover:bg-blue-700 text-xs text-white font-bold py-2 px-4 rounded"
                onClick={() => setIsModalOpen(true)}
            >
                + Add Click Device
            </button>
         
            <div className="container">

                {Object.entries(devicesState).map(([site, devices]) => (
                    <div key={site} className=''>
                        <div className='mt-5 p-3 p-2 text-sm font-semibold'>Site: {site}</div>
                        <table className="border-collapse border border-gray-200 bg-white">
                            <thead className='bg-gray-50'>
                                <tr>
                                    <th className="border border-gray-300 text-xs">Device Name</th>
                                    <th className="border border-gray-300 p-1 text-xs">Network 1</th>
                                    <th className="border border-gray-300 p-1 text-xs">Network 2</th>
                                    <th className="border border-gray-300 p-1 text-xs">Network 3</th>
                                    <th className="border border-gray-300 p-1 text-xs">Network 4</th>
                                    <th className="border border-gray-300 p-1 text-xs">Network 5</th>
                                    <th className="border border-gray-300 p-1 text-xs">Proxy</th>
                                    <th className="border border-gray-300 p-1 text-xs">Port</th>
                                    <th className="border border-gray-300 p-1 text-xs">Actions</th>
                                    <th className="border border-gray-300 p-1 text-xs">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {devices.map(device => {
                                    const isOnline = moment().diff(moment(device.last_request_time), 'minutes') < 15;
                                    return (
                                        <tr key={device.id}>
                                            <td className="border border-gray-300 p-1 text-xs">
                                                <input type="text" value={device.device_name || ''}
                                                    onChange={(e) => handleInputChange(site, device.id, 'device_name', e.target.value)}
                                                    className="p-1 border border-gray-300 rounded" />
                                            </td>
                                            <td className="border border-gray-300 p-1 text-xs">
                                                <input type="text" name="network1" value={device.network1 || ''}
                                                    onChange={(e) => handleInputChange(site, device.id, 'network1', e.target.value)}
                                                    className="p-1 border border-gray-300 rounded" />
                                            </td>
                                            <td className="border border-gray-300 p-1 text-xs">
                                                <input type="text" value={device.network2 || ''}
                                                    onChange={(e) => handleInputChange(site, device.id, 'network2', e.target.value)}
                                                    className="p-1 border border-gray-300 rounded" />
                                            </td>
                                            <td className="border border-gray-300 p-1 text-xs">
                                                <input type="text" value={device.network3 || ''}
                                                    onChange={(e) => handleInputChange(site, device.id, 'network3', e.target.value)}
                                                    className="p-1 border border-gray-300 rounded" />
                                            </td>
                                            <td className="border border-gray-300 p-1 text-xs">
                                                <input type="text" value={device.network4 || ''}
                                                    onChange={(e) => handleInputChange(site, device.id, 'network4', e.target.value)}
                                                    className="p-1 border border-gray-300 rounded" />
                                            </td>
                                            <td className="border border-gray-300 p-1 text-xs">
                                                <input type="text" value={device.network5 || ''}
                                                    onChange={(e) => handleInputChange(site, device.id, 'network5', e.target.value)}
                                                    className="p-1 border border-gray-300 rounded" />
                                            </td>
                                            <td className="border border-gray-300 p-1 text-xs">
                                                <input type="text" value={device.proxy || ''}
                                                    onChange={(e) => handleInputChange(site, device.id, 'proxy', e.target.value)}
                                                    className="p-1 border border-gray-300 rounded" />
                                            </td>
                                            <td className="border border-gray-300 p-1 text-xs">
                                                <input type="text" value={device.port || ''}
                                                    onChange={(e) => handleInputChange(site, device.id, 'port', e.target.value)}
                                                    className="p-1 border border-gray-300 rounded" />
                                            </td>
                                            <td className="flex border border-gray-300 p-1 text-xs">
                                                <button className="bg-blue-500 text-white px-4 py-1 rounded mr-2" onClick={() => updateDeviceData(device)}>Save</button>
                                                <button
                                                    className="bg-red-500 text-white px-4 py-1 rounded"
                                                    onClick={() => deleteDeviceData(site, device.id)}
                                                >
                                                    Delete
                                                </button>
                                            </td>
                                            <td className="border border-gray-300 p-1 text-xs">
                                                <span className={`px-2 py-1 rounded-full text-white text-xs ${isOnline ? 'bg-green-400' : 'bg-red-200'}`}>
                                                    {isOnline ? 'Online' : 'Offline'}
                                                </span>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                ))}
            </div>
            {isModalOpen && (
                <div className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal title" role="dialog" aria-modal="true">
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <div className="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

                        <div className="fixed inset-0 flex items-center justify-center z-10">
                            <div className="bg-white dark:bg-gray-700 rounded-lg shadow-lg p-4 md:max-w-md w-full">
                            <div className="flex justify-center">
                            <div className="space-y-6 px-6 lg:px-8 pb-4 sm:pb-6 xl:pb-8">
                            <h3 className="text-xl font-medium text-gray-900 dark:text-white">Add Click Device</h3>
                                    <div className="mb-4">
                                        <label className="block text-gray-700 text-sm font-bold mb-2">
                                            Site
                                        </label>
                                        <input
                                            type="text"
                                            value={newDevice.site}
                                            onChange={(e) => setNewDevice({ ...newDevice, site: e.target.value })}
                                            className="p-1 border border-gray-300 rounded"
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-gray-700 text-sm font-bold mb-2">
                                            Device Name
                                        </label>
                                        <input
                                            type="text"
                                            value={newDevice.device_name}
                                            onChange={(e) => setNewDevice({ ...newDevice, device_name: e.target.value })}
                                            className="p-1 border border-gray-300 rounded"
                                        />
                                    </div>
                                    <button
                                        className="bg-blue-500 hover:bg-blue-700 text-white text-sm font-bold py-2 px-4 rounded"
                                        onClick={handleSaveNewDevice}
                                    >
                                        Save
                                    </button>
                                    <button
                                        className="bg-gray-300 text-gray-700 text-sm font-bold py-2 px-4 rounded ml-2"
                                        onClick={() => setIsModalOpen(false)}
                                    >
                                        Cancel
                                    </button>
                                    </div>        
                                </div>
                                
                            </div>
                            
                        </div>
                    </div>
                </div>
            )}
        </div>

    );
}

export default TableClickerDevice;