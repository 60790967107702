import React from 'react';
import { useSelector } from 'react-redux';


const NetworkTableData = () => {
    const networkTableData = useSelector(state => state.table.networkTableData);

  return (
    <div className='bg-white rounded-sm'>
      <div className='border-b p-1 border-gray-200 p-3 text-sm font-semibold'>
        Thống Kê Network
      </div>
      <table className='w-full divide-y divide-gray-200'>
        <thead className='bg-gray-50'>
          <tr>
            <th scope='col' className='px-3 py-3 text-center text-xs font-bolder text-gray-500 uppercase tracking-wider'>
             Network
            </th>
            <th scope='col' className='px-3 py-3 text-center text-xs font-bolder text-gray-500 uppercase tracking-wider'>
              Doanh thu
            </th>
            <th scope='col' className='px-3 py-3 text-center text-xs font-bolder text-gray-500 uppercase tracking-wider'>
              Lead
            </th>
          </tr>
        </thead>
        <tbody className='bg-white divide-y divide-gray-200'>
          {networkTableData.map((entry, index) => (
            <tr key={index}>
              <td className='px-6 py-2 whitespace-nowrap text-sm text-center font-medium text-gray-900'>{entry.network}</td>
              <td className='px-6 py-2 whitespace-nowrap text-sm text-center text-gray-500'>$ {entry.revenue}</td>
              <td className='px-6 py-2 whitespace-nowrap text-sm text-center text-gray-500'>{entry.lead}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default NetworkTableData;
