import React, { useState } from 'react';
import IproxyControl from "./proxy/iproxy";
import FastProxyControl from "./proxy/fastproxy";
import Statistics from "./overview/statistics ";
function Proxy() {
    // Sử dụng state để theo dõi thứ tự của các component
    const [order, setOrder] = useState(['IproxyControl', 'FastProxyControl']);

    // Hàm để tráo đổi vị trí của IproxyControl và FastProxyControl
    const swapComponents = () => {
        setOrder(prevOrder => prevOrder[0] === 'IproxyControl' ? ['FastProxyControl', 'IproxyControl'] : ['IproxyControl', 'FastProxyControl']);
    };

    return (
        <div className="mt-16">
            <div className="charttrend">
                <div className='mt-5 '>
                    <Statistics />
                </div>
                <div className="flex items-center gap-5 pl-5 shadow-sm">
                    
                    <button className='mt-2 mb-2 bg-blue-500 text-white px-4 py-1 rounded mr-2 text-xs' onClick={swapComponents}>Đổi Vị Trí Proxy</button>

                </div>
                <div style={{ height: 'calc(100vh - 230px)' }}  className="overflow-auto proxycontrol mb-6  grid gap-y-5 gap-x-6 md:grid-cols-2 xl:grid-cols-2 p-1">
                    
                    {order.map(component => (
                        component === 'IproxyControl' ? (
                            <div key={component} className='rounded-sm max-w-full h-screen overflow-auto'>
                                <div className='bg-white border-b p-1 border-gray-200 p-3 text-sm font-semibold mb-5'>
                                    Proxy US Unlimited Bandwidth
                                </div>
                                <IproxyControl />
                            </div>
                        ) : (
                            <div key={component} className='rounded-sm max-w-full h-screen overflow-auto'>
                                <div className='bg-white border-b p-1 border-gray-200 p-3 text-sm font-semibold mb-5'>
                                    Proxy GLOBAL
                                </div>
                                <FastProxyControl />
                            </div>
                        )
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Proxy;
