import React, { useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useSelector, useDispatch } from 'react-redux';
import { GridLoader } from 'react-spinners';
import { fetchUsersAnalyticChartData } from '../../../services/analyticsSlice'; // Import the action

const UsersAnalyticsChart = () => {
  const dispatch = useDispatch();
  const { startDate, endDate } = useSelector(state => state.dateRange); // Assuming you have a dateRange in your state
  const chartData = useSelector(state => state.analytics.usersAnalyticChartData);

  useEffect(() => {
    if (startDate && endDate) {
        // Format the dates if necessary
        const formattedStartDate = startDate; // Format if needed
        const formattedEndDate = endDate; // Format if needed
        dispatch(fetchUsersAnalyticChartData({ startDate: formattedStartDate, endDate: formattedEndDate }));
    }
}, [dispatch, startDate, endDate]);


const hasChartData = chartData && chartData.series && chartData.categories;
    const series = hasChartData ? chartData.series : [];
    const categories = hasChartData ? chartData.categories : [];

  const chartOptions = {
    chart: {
      height: 500,
      type: 'bar',
      stacked: true,
      toolbar: {
        show: false
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        columnWidth: '55%',
      },
    },
    dataLabels: {
      enabled: true
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    colors: ["#008FFB", "#00E396", "#FEB019", '#FF4560', '#775DD0', '#449DD1', '#F86624', '#6b709c', '#d64bd9', '#753ce3', '#613825'],
    series: series,
    xaxis: {
      categories: categories,
      axisBorder: {
        show: true,
        color: '#bec7e0',
      },
      axisTicks: {
        show: true,
        color: '#bec7e0',
      },
    },
    legend: {
      offsetY: 6,
    },
    yaxis: {
      title: {
        text: ''
      }
    },
    fill: {
      opacity: 1
    },
    grid: {
      row: {
        colors: ['transparent', 'transparent'],
        opacity: 0.2
      },
      borderColor: '#f1f3fa'
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return "$ " + val
        }
      }
    }
  };

 

  return (
    <div className='bg-white rounded-sm'>
      <div className='border-b p-1 border-gray-200 p-3 text-sm font-semibold'>
        Thống Kê Cụ Thể Theo Từng Nhân Viên
      </div>
      {hasChartData ? (
        <ReactApexChart options={chartOptions} series={series} type="bar" height={500} />
      ) : (
        <div className='h-64 flex justify-center items-center'><GridLoader color={"#123abc"} /> {/* Customize color as needed */}</div>
      )}
    </div>
  );
};

export default UsersAnalyticsChart;
