
function Footer(){
    return(
        <div className="footer">
        
              

<footer className="bg-white rounded-lg shadow m-4 dark:bg-gray-800">
    <div className="w-full mx-auto max-w-screen-xl p-4 md:flex md:items-center md:justify-between">
      <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">© 2023 <a href="https://dash.saviartmedia.com/" className="hover:underline">SaviartMedia™</a>. All Rights Reserved.
    </span>
    <ul className="flex flex-wrap items-end mt-3 text-sm font-medium text-gray-500 dark:text-gray-400 sm:mt-0">
        <li>
            <span className="me-4 md:me-6">Crafted with by Nguyễn Sơn</span>
        </li>
        <li>
            <span className="me-4 md:me-6">Contact: +84335190991</span>
        </li>
    </ul>
    </div>
</footer>


        </div>
    )
}
export default Footer;