import React, { useEffect, useState } from 'react';

const AnimatedDigit = ({ value = 0, index }) => { // Cung cấp một giá trị mặc định là 0
  const stringValue = value.toString(); // Chuyển đổi một lần và sử dụng lại, ngăn chặn việc gọi .toString() nhiều lần
  const [prevValue, setPrevValue] = useState(stringValue);
  const [digits, setDigits] = useState([...stringValue]);
  const [prevDigits, setPrevDigits] = useState([...stringValue]);

  useEffect(() => {
    // Biến đổi giá trị hiện tại và trước đó thành mảng của các chữ số
    const newValueStr = value.toString();
    const newDigits = [...newValueStr];
    const newPrevDigits = digits.map((digit, idx) => (newValueStr[idx] !== digit ? digit : ' '));

    setPrevValue(stringValue);
    setPrevDigits(newPrevDigits);
    // Sử dụng một timeout để đảm bảo rằng state được cập nhật sau khi animation bắt đầu
    const timer = setTimeout(() => setDigits(newDigits), 500);

    // Clear the timeout if the component is unmounted or the value changes
    return () => clearTimeout(timer);
  }, [value, stringValue, digits]);

  return (
    <div className="flex justify-end">
      {digits.map((digit, idx) => (
        <div key={`${index}-${idx}`} className="relative h-6 overflow-hidden ">
          <div className={`absolute w-full h-full flex items-center justify-center text-xl ${prevDigits[idx] !== ' ' ? 'animate-slideDown' : 'animate-none'}`}>
            {prevDigits[idx]}
          </div>
          <div className={`w-full h-full flex items-center justify-center text-xl ${prevDigits[idx] !== ' ' ? 'animate-slideUp' : 'animate-none'}`}>
            {digit}
          </div>
        </div>
      ))}
    </div>
  );
};

export default AnimatedDigit;
