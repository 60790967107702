import React, { useState, useEffect } from 'react';
import { fetchAuth } from '../../services/AuthService'
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
function LoginForm() {

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      const response = await fetchAuth(username, password);
      localStorage.setItem('accessToken', response.access); // Lưu access token
      localStorage.setItem('userRole', response.role);
      localStorage.setItem('username', response.name);
      localStorage.setItem('memname', response.memname);
      navigate('/general');
    } catch (error) {
      console.error('Đăng nhập thất bại', error);
    }
  };


  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      try {
        const decodedToken = jwtDecode(accessToken);
        console.log(decodedToken)
        const isTokenExpired = decodedToken.exp * 1000 < new Date().getTime();
        console.log(isTokenExpired)
        if (!isTokenExpired) {
          // Token còn hiệu lực, chuyển hướng đến trang overview
          navigate('/overview');
        }
      } catch (error) {
        // Lỗi khi giải mã token, có thể token không hợp lệ
        console.error('Token decoding failed', error);
        localStorage.removeItem('accessToken'); // Xóa token không hợp lệ
        navigate('/login');
      }
    }
    // Chỉ chạy useEffect này một lần khi component mount
  }, [navigate]);




  return (
    <div className='w-full h-full flex justify-center items-center'>
    <div className="mt-20 login-form-container">
      <div className="logo-container">
        Welcome Back!
      </div>


      <div className="line"></div>
      <form className="form" onSubmit={handleLogin} >
        <div className="form-group">
          <label>Email</label>
          <input required="" placeholder="Enter your email" name="email" id="email" type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
        </div>

        <div className="form-group">
          <label>Password</label>
          <input required="" name="password" placeholder="Enter your password" id="password" type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
        </div>

        <button type="submit" className="form-submit-btn">Sign In</button>
      </form>

      <a className="forgot-password-link link" href="#">Forgot Password</a>

      <p className="signup-link">
        Don't have an account?
        <a href="#" className="signup-link link"> Sign up now</a>
      </p>
    </div>
    </div>
  );
}

export default LoginForm;
