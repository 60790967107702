import React from 'react';
import { useSelector } from 'react-redux';
import ReactApexChart from 'react-apexcharts';

const SitesTrendChart = () => {
  // Sử dụng selector từ Redux để lấy dữ liệu
  const chartData = useSelector(state => state.charts.sitesTrendChartData);


  // Tạo dữ liệu series cho biểu đồ
  const seriesData = chartData.map(site => ({
    name: site.name,
    data: site.data.map(entry => entry.y),
  }));

  // Tạo cấu hình trục x
  const xaxisConfig = {
    type: 'day',
    categories: chartData.length > 0 ? chartData[0].data.map(entry => entry.x) : [],
  };

  // Cấu hình biểu đồ
  const chartOptions = {
    chart: {
      type: 'bar',
      height: 350,
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '45%',
      },
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      width: 1,
      colors: ['#fff']
    },
    xaxis: xaxisConfig,
    yaxis: {
      title: {
        
      },
    },
    fill: {
      opacity: 1
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val + " $";
        }
      }
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
      offsetY: 5
    }
  };

  return (
    <div className='bg-white rounded-sm'>
      <div className='border-b p-1 border-gray-200 p-3 text-sm font-semibold'>
        Thống Kê Site Theo Từng Ngày
      </div>
      <ReactApexChart
        className="bg-white rounded-lg"
        options={chartOptions}
        series={seriesData}
        type="bar"
        height={350}
      />
    </div>
  );
}

export default SitesTrendChart;
