

function SitesAnalytics() {
    return (<div className="mt-12 mt-20">
        <div className="statistics">
            <div className="pl-5">
                <div className="font-semibold">
                    Statitics
                </div>
                <div className="text-xs">
                    Tóm tắt đánh giá kết quả chung của công việc
                </div>
            </div>
            <div>
                <div className="charttrend mt-12 mb-6 grid gap-y-5 gap-x-6 md:grid-cols-2 xl:grid-cols-2 p-1">



                </div>
            </div>
        </div>

    </div>)
}
export default SitesAnalytics;