import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { GridLoader } from 'react-spinners';
import { fetchUsersAnalyticsTableData } from '../../../services/analyticsSlice'; // Import the action

const UsersAnalyticsTable = () => {
    const dispatch = useDispatch();
    const { startDate, endDate } = useSelector(state => state.dateRange); // Assuming you have a dateRange in your state
    const usersAnalyticsTableData = useSelector(state => state.analytics.usersAnalyticsTableData);
    const isLoading = useSelector(state => state.analytics.loading.usersAnalyticsTable);
    useEffect(() => {
        if (startDate && endDate) {
            // Format the dates if necessary
            const formattedStartDate = startDate; // Format if needed
            const formattedEndDate = endDate; // Format if needed
            dispatch(fetchUsersAnalyticsTableData({ startDate: formattedStartDate, endDate: formattedEndDate }));
        }
    }, [dispatch, startDate, endDate]);


    if (isLoading) {
        return (
            <div className='bg-white rounded-sm'>
            <div className='border-b p-1 border-gray-200 p-3 text-sm font-semibold'>
                Thống Kê Chung
            </div>
            <div className='h-64 flex justify-center items-center'><GridLoader color={"#123abc"} /> {/* Customize color as needed */}</div>
        </div>
               
           
        );
    }

    return (
        <div className='bg-white rounded-sm'>
            <div className='border-b p-1 border-gray-200 p-3 text-sm font-semibold'>
                Thống Kê Chung
            </div>
            <table className='w-full divide-y divide-gray-200'>
                <thead className='bg-gray-50'>
                    <tr>
                        <th scope='col' className='px-3 py-3 text-center text-xs font-bolder text-gray-500 uppercase tracking-wider'>
                            Username
                        </th>
                        <th scope='col' className='px-3 py-3 text-center text-xs font-bolder text-gray-500 uppercase tracking-wider'>
                            Số Tài Khoản
                        </th>
                        <th scope='col' className='px-3 py-3 text-center text-xs font-bolder text-gray-500 uppercase tracking-wider'>
                            Số Lead
                        </th>
                        <th scope='col' className='px-3 py-3 text-center text-xs font-bolder text-gray-500 uppercase tracking-wider'>
                            Thu nhập
                        </th>
                    </tr>
                </thead>
                <tbody className='bg-white divide-y divide-gray-200'>
                    {usersAnalyticsTableData.map((user, index) => (
                        <tr key={index}>
                            <td className='px-6 py-2 whitespace-nowrap text-sm text-center font-medium text-gray-900'>{user.username}</td>
                            <td className='px-6 py-2 whitespace-nowrap text-sm text-center text-gray-500'>{user.account}</td>
                            <td className='px-6 py-2 whitespace-nowrap text-sm text-center text-gray-500'>{user.lead}</td>
                            <td className='px-6 py-2 whitespace-nowrap text-sm text-center text-gray-500'>$ {user.revenue}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default UsersAnalyticsTable;
