import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from './axios'; // Make sure this path is correct
import moment from 'moment-timezone';

// Initial state for the slice
const initialState = {
  networkTableData: [], // Data for the network table
  topOfferTableData: [], // Data for the top offer table
  sitesTableData: [], // Data for the sites table
  loading: {
    networkTable: false,
    topOfferTable: false,
    sitesTable: false, // Loading state for the sites table
  },
  error: {
    networkTable: null,
    topOfferTable: null,
    sitesTable: null, // Error state for the sites table
  },
};

// Async thunk for fetching Network Table Data
export const fetchNetworkTableData = createAsyncThunk(
  'table/fetchNetworkTableData',
  async ({ startDate, endDate }, { rejectWithValue }) => {
    try {
      const endOfDay = moment(endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss');
      const response = await axios.get(`/api/v1/networktable-data/?start_date=${startDate}&end_date=${endOfDay}`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

// Async thunk for fetching Top Offer Table Data
export const fetchTopOfferTableData = createAsyncThunk(
  'table/fetchTopOfferTableData',
  async ({ startDate, endDate }, { rejectWithValue }) => {
    try {
      const endOfDay = moment(endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss');
      const response = await axios.get(`/api/v1/topoffertable-data/?start_date=${startDate}&end_date=${endOfDay}`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

// Async thunk for fetching Sites Table Data
export const fetchSitesTableData = createAsyncThunk(
  'table/fetchSitesTableData',
  async ({ startDate, endDate }, { rejectWithValue }) => {
    try {
      const endOfDay = moment(endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss');
      const response = await axios.get(`/api/v1/sitestable-data/?start_date=${startDate}&end_date=${endOfDay}`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

// Slice definition
const tableSlice = createSlice({
  name: 'table',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Network Table Data
      .addCase(fetchNetworkTableData.pending, (state) => {
        state.loading.networkTable = true;
      })
      .addCase(fetchNetworkTableData.fulfilled, (state, action) => {
        state.networkTableData = action.payload;
        state.loading.networkTable = false;
      })
      .addCase(fetchNetworkTableData.rejected, (state, action) => {
        state.error.networkTable = action.payload;
        state.loading.networkTable = false;
      })
      // Top Offer Table Data
      .addCase(fetchTopOfferTableData.pending, (state) => {
        state.loading.topOfferTable = true;
      })
      .addCase(fetchTopOfferTableData.fulfilled, (state, action) => {
        state.topOfferTableData = action.payload;
        state.loading.topOfferTable = false;
      })
      .addCase(fetchTopOfferTableData.rejected, (state, action) => {
        state.error.topOfferTable = action.payload;
        state.loading.topOfferTable = false;
      })
      // Sites Table Data
      .addCase(fetchSitesTableData.pending, (state) => {
        state.loading.sitesTable = true;
      })
      .addCase(fetchSitesTableData.fulfilled, (state, action) => {
        state.sitesTableData = action.payload;
        state.loading.sitesTable = false;
      })
      .addCase(fetchSitesTableData.rejected, (state, action) => {
        state.error.sitesTable = action.payload;
        state.loading.sitesTable = false;
      });
  },
});

export default tableSlice.reducer;
