// userSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "./axios";

// Define initial state
const initialState = {
  users: [], // Sử dụng tên 'users' cho danh sách người dùng
  status: "idle",
  error: null,
};

// Define the asynchronous thunk to fetch Users from the API
export const fetchUsers = createAsyncThunk("users/fetchUsers", async () => {
  try {
    const response = await axios.get("/api/users/");
    return response; // Trả về data từ response thay vì response trực tiếp
  } catch (error) {
    throw error;
  }
});

// Define the asynchronous thunk to create a new User
export const createUser = createAsyncThunk("users/createUser", async (user) => {
  try {
    const response = await axios.post("/api/users/", user);
    return response;
  } catch (error) {
    throw error;
  }
});

// Define the asynchronous thunk to update a User
export const updateUser = createAsyncThunk("users/updateUser", async (user) => {
  try {
    const response = await axios.put(`/api/users/`, user);
    return response;
  } catch (error) {
    throw error;
  }
});

// Define the asynchronous thunk to delete a User
export const deleteUser = createAsyncThunk("users/deleteUser", async (userId) => {
  try {
    await axios.delete(`/api/users/`);
    return userId;
  } catch (error) {
    throw error;
  }
});

// Create a userSlice
const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.users = action.payload;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(createUser.fulfilled, (state, action) => {
        state.users.push(action.payload);
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        const updatedUser = action.payload;
        const existingUserIndex = state.users.findIndex((user) => user.id === updatedUser.id);
        if (existingUserIndex !== -1) {
          state.users[existingUserIndex] = updatedUser;
        }
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        const userId = action.payload;
        state.users = state.users.filter((user) => user.id !== userId);
      });
  },
});

export default userSlice.reducer;
