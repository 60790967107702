import React, { useEffect, useState } from 'react';
import Datepicker from "react-tailwindcss-datepicker";
import { useDispatch, useSelector } from 'react-redux';
import { setDateRange } from '../../services/dateRangeSlice';
import { fetchStatistics } from '../../services/statisticsSlice';
import { fetchWorkTrendChartData, fetchSitesTrendChartData, fetchNetworkTrendChartData } from '../../services/chartsSlice';
import { fetchNetworkTableData, fetchSitesTableData, fetchTopOfferTableData } from '../../services/tableSlice';
import axios from '../../services/axios';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import Breadcrumb from './breadcrumb-header';

function Header({ toggleSidebar, isSidebarOpen }) {
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const username = localStorage.getItem('username');
  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);
  useEffect(() => {
    const closeDropdown = (e) => {
      if (!e.target.closest('.dropdown-button')) {
        setIsDropdownOpen(false);
      }
    };
    document.body.addEventListener('click', closeDropdown);
    return () => document.body.removeEventListener('click', closeDropdown);
  }, []);


  const dispatch = useDispatch();
  const currentDate = new Date();
  const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
  const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
  const [value, setValue] = useState({
    startDate: firstDayOfMonth,
    endDate: lastDayOfMonth
  });

  const signOut = async () => {
    try {
      await axios.post('/api/signout/');
      localStorage.clear();
      navigate('/login');
    } catch (error) {
      console.error('Error during sign out', error);
      // Handle error, maybe show a message to the user
    }
  };

  const handleSignOut = () => {
    signOut().then(() => {
      // Redirect to login page after sign out
      // Example: navigate('/login');
    });
  };

  // Sử dụng useSelector để lấy dữ liệu từ Redux
  const initialDataFetched = useSelector(state => state.statistics.initialDataFetched);

  useEffect(() => {
    // Chuyển đổi ngày tháng thành định dạng chuẩn
    const formattedStartDate = moment(value.startDate).format('YYYY-MM-DD');
    const formattedEndDate = moment(value.endDate).format('YYYY-MM-DD');
    dispatch(setDateRange({
      startDate: formattedStartDate,
      endDate: formattedEndDate
    }));
    // Chỉ dispatch dữ liệu lần đầu (nếu chưa fetch dữ liệu ban đầu)
    if (!initialDataFetched) {
      dispatch(fetchStatistics({ startDate: formattedStartDate, endDate: formattedEndDate }));
      dispatch(fetchWorkTrendChartData({ startDate: formattedStartDate, endDate: formattedEndDate }));
      dispatch(fetchSitesTrendChartData({ startDate: formattedStartDate, endDate: formattedEndDate }));
      dispatch(fetchNetworkTrendChartData({ startDate: formattedStartDate, endDate: formattedEndDate }));
      dispatch(fetchNetworkTableData({ startDate: formattedStartDate, endDate: formattedEndDate }));
      dispatch(fetchTopOfferTableData({ startDate: formattedStartDate, endDate: formattedEndDate }));
      dispatch(fetchSitesTableData({ startDate: formattedStartDate, endDate: formattedEndDate }));
      // Đặt cờ đã fetch dữ liệu ban đầu thành true
      // (lần sau sẽ không dispatch lại)
      dispatch({ type: 'SET_INITIAL_DATA_FETCHED' });
    }
  }, [dispatch, initialDataFetched, value.startDate, value.endDate]);

  const handleValueChange = newValue => {
    // Check if both startDate and endDate are not null
    if (newValue && newValue.startDate && newValue.endDate) {
      setValue(newValue);

      // Convert dates to the required format
      const formattedStartDate = moment(newValue.startDate).format('YYYY-MM-DD');
      const formattedEndDate = moment(newValue.endDate).format('YYYY-MM-DD');

      // Dispatch the action to set the new date range
      dispatch(setDateRange({ startDate: formattedStartDate, endDate: formattedEndDate }));
    }
  };
  const headerWidthClass = isSidebarOpen ? 'header-open' : 'header-closed';


  return (
    <div className={`header ${headerWidthClass} flex flex-row items-center justify-between p-2 border-b border-gray-200 shadow-sm`}>
      <button
        className="button-menu-mobile"
        onClick={toggleSidebar} >
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="0.5" y="0.5" width="27" height="27" rx="7.5" fill="#161A23" />
          <rect x="0.5" y="0.5" width="27" height="27" rx="7.5" stroke="#5C5F65" />
          <path d="M11.8048 11.1381C12.0651 10.8777 12.0651 10.4556 11.8048 10.1953C11.5444 9.93491 11.1223 9.93491 10.862 10.1953L7.52864 13.5286C7.26829 13.7889 7.26829 14.2111 7.52864 14.4714L10.862 17.8047C11.1223 18.0651 11.5444 18.0651 11.8048 17.8047C12.0651 17.5444 12.0651 17.1223 11.8048 16.8619L8.94285 14L11.8048 11.1381Z" fill="#C0C0C0" />
          <path d="M17.1381 10.1953C16.8778 9.93491 16.4557 9.93491 16.1953 10.1953C15.935 10.4556 15.935 10.8777 16.1953 11.1381L19.0572 14L16.1953 16.8619C15.935 17.1223 15.935 17.5444 16.1953 17.8047C16.4557 18.0651 16.8778 18.0651 17.1381 17.8047L20.4714 14.4714C20.7318 14.2111 20.7318 13.7889 20.4714 13.5286L17.1381 10.1953Z" fill="#C0C0C0" />
        </svg>
      </button>

      <div className="flex flex-row flex-nowrap items-center justify-start gap-4 w-full">
        <div className="hidden sm:flex flex-row items-center gap-1 overflow-hidden">
          <div className="items-stretch content-center flex-wrap flex justify-between gap-2 rounded-lg">
            <div className="justify-center items-center flex aspect-square flex-col p-1 rounded-lg">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3 10C3 7.17157 3 5.75736 3.87868 4.87868C4.75736 4 6.17157 4 9 4H15C17.8284 4 19.2426 4 20.1213 4.87868C21 5.75736 21 7.17157 21 10C21 12.8284 21 14.2426 20.1213 15.1213C19.2426 16 17.8284 16 15 16H9C6.17157 16 4.75736 16 3.87868 15.1213C3 14.2426 3 12.8284 3 10Z" fill="#7E869E" fillOpacity="0.25" />
                <path fillRule="evenodd" clipRule="evenodd" d="M5.56689 12H4.8558C4.05238 12 3.65068 12 3.37733 12.2175C3.31819 12.2646 3.26457 12.3182 3.21751 12.3773C3 12.6507 3 13.0524 3 13.8558V15C3 17.8284 3 19.2426 3.87868 20.1213C4.75736 21 6.17157 21 9 21H15C17.8284 21 19.2426 21 20.1213 20.1213C21 19.2426 21 17.8284 21 15V13.8558C21 13.0524 21 12.6507 20.7825 12.3773C20.7354 12.3182 20.6818 12.2646 20.6227 12.2175C20.3493 12 19.9476 12 19.1442 12H18.4331C17.7346 12 17.3853 12 17.0908 12.0932C16.5803 12.2548 16.1559 12.6143 15.9126 13.0913C15.7723 13.3665 15.7148 13.711 15.6 14.4L15.6 14.4L15.6 14.4L15.6 14.4001C15.5713 14.5723 15.5569 14.6584 15.5218 14.7272C15.461 14.8464 15.3549 14.9363 15.2273 14.9767C15.1537 15 15.0664 15 14.8917 15H9.10828C8.93365 15 8.84633 15 8.7727 14.9767C8.64508 14.9363 8.53897 14.8464 8.47815 14.7272C8.44306 14.6584 8.42871 14.5723 8.4 14.4C8.28517 13.711 8.22775 13.3665 8.08739 13.0913C7.8441 12.6143 7.4197 12.2548 6.90919 12.0932C6.61466 12 6.26541 12 5.56689 12ZM9.5 17C9.22386 17 9 17.2239 9 17.5C9 17.7761 9.22386 18 9.5 18H14.5C14.7761 18 15 17.7761 15 17.5C15 17.2239 14.7761 17 14.5 17H9.5Z" fill="#222222" />
              </svg>

            </div>
            <div className="justify-center items-center flex aspect-square flex-col p-1 rounded-lg">

            </div>
          </div>
          <Breadcrumb />
        </div>
        <div className="flex flex-row flex-nowrap items-center gap-2 ml-auto">
          <div className="nav-link">
            <div className="text-sm md:flex hidden">
              Khoảng thời gian đang thống kê dữ liệu </div>
          </div>
          <div className="flex flex-row flex-nowrap items-center shadow-sm">
            <Datepicker value={value} onChange={handleValueChange} showShortcuts={true} primaryColor={"rose"} />
          </div>
        </div>
        <div className="flex items-center justify-end">
          <div className="shadow-sm bg-white flex items-start pl-2 pr-3 rounded-[10px] md:gap-1">

            <span className='text-sm object-center overflow-hidden self-center shrink-0 max-w-full my-auto'>
              {username || 'User'} {/* Display the username, or 'User' if it's not found */}
            </span>

            <img
              loading="lazy"
              srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/747e1172cbae769c49c02d098615794566c83b32946cb13a4ed8121e422fc62b?apiKey=d4c32063bd164f59adc23c3951ad3f9d&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/747e1172cbae769c49c02d098615794566c83b32946cb13a4ed8121e422fc62b?apiKey=d4c32063bd164f59adc23c3951ad3f9d&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/747e1172cbae769c49c02d098615794566c83b32946cb13a4ed8121e422fc62b?apiKey=d4c32063bd164f59adc23c3951ad3f9d&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/747e1172cbae769c49c02d098615794566c83b32946cb13a4ed8121e422fc62b?apiKey=d4c32063bd164f59adc23c3951ad3f9d&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/747e1172cbae769c49c02d098615794566c83b32946cb13a4ed8121e422fc62b?apiKey=d4c32063bd164f59adc23c3951ad3f9d&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/747e1172cbae769c49c02d098615794566c83b32946cb13a4ed8121e422fc62b?apiKey=d4c32063bd164f59adc23c3951ad3f9d&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/747e1172cbae769c49c02d098615794566c83b32946cb13a4ed8121e422fc62b?apiKey=d4c32063bd164f59adc23c3951ad3f9d&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/747e1172cbae769c49c02d098615794566c83b32946cb13a4ed8121e422fc62b?apiKey=d4c32063bd164f59adc23c3951ad3f9d&"
              className="aspect-square object-contain object-center w-[41px] justify-center items-center overflow-hidden self-stretch shrink-0 max-w-full"
            />
            <div className='object-center self-center dropdown-button focus:outline-none cursor-pointer' onClick={toggleDropdown}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={19}
                height={19}
                fill="none"
              >
                <path
                  stroke="#0095FF"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M11.502 2.443c0-.835-.61-1.544-1.436-1.67a2.111 2.111 0 0 0-.632 0 1.689 1.689 0 0 0-1.436 1.67v1.181c-.471.135-.92.322-1.34.556l-.836-.835a1.689 1.689 0 0 0-2.196-.166c-.17.126-.32.277-.447.447a1.689 1.689 0 0 0 .166 2.196l.835.835a6.33 6.33 0 0 0-.556 1.341h-1.18c-.836 0-1.545.61-1.67 1.436-.032.21-.032.423 0 .632a1.689 1.689 0 0 0 1.67 1.436h1.18c.135.471.322.92.556 1.34l-.835.836c-.59.59-.66 1.524-.166 2.196.126.17.277.32.447.447a1.688 1.688 0 0 0 2.196-.166l.835-.835c.42.234.87.421 1.341.556v1.18c0 .836.61 1.545 1.436 1.67.21.032.423.032.632 0a1.688 1.688 0 0 0 1.436-1.67v-1.18a6.335 6.335 0 0 0 1.34-.556l.836.835c.59.59 1.523.66 2.196.166a2.11 2.11 0 0 0 .447-.447 1.688 1.688 0 0 0-.166-2.196l-.835-.835c.234-.42.421-.87.556-1.341h1.18c.836 0 1.545-.61 1.67-1.436.032-.21.032-.422 0-.632a1.688 1.688 0 0 0-1.67-1.436h-1.18a6.332 6.332 0 0 0-.556-1.34l.835-.836c.59-.59.66-1.524.166-2.196a2.11 2.11 0 0 0-.447-.447 1.689 1.689 0 0 0-2.196.166l-.835.835a6.328 6.328 0 0 0-1.341-.556v-1.18z"
                />
                <path
                  stroke="#363853"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M15.874 16.32a1.689 1.689 0 0 1-2.196-.165l-.835-.835c-.42.234-.87.421-1.341.556v1.18c0 .836-.61 1.545-1.436 1.67-.21.032-.423.032-.632 0a1.689 1.689 0 0 1-1.436-1.67v-1.18a6.333 6.333 0 0 1-1.34-.556l-.836.835c-.59.59-1.524.66-2.196.166a2.11 2.11 0 0 1-.447-.447 1.689 1.689 0 0 1 .166-2.196l.835-.835a6.329 6.329 0 0 1-.556-1.341h-1.18c-.836 0-1.545-.61-1.67-1.436a2.111 2.111 0 0 1 0-.632 1.688 1.688 0 0 1 1.67-1.436h1.18a6.33 6.33 0 0 1 .556-1.34l-.835-.836a1.689 1.689 0 0 1-.166-2.196c.126-.17.277-.32.447-.447a1.689 1.689 0 0 1 2.196.166l.835.835c.42-.234.87-.421 1.341-.556v-1.18c0-.836.61-1.545 1.436-1.67.21-.032.423-.032.632 0a1.688 1.688 0 0 1 1.436 1.67v1.18c.471.135.92.322 1.34.556l.836-.835c.59-.59 1.523-.66 2.196-.166"
                />
                <path
                  stroke="#0095FF"
                  strokeWidth={1.5}
                  d="M7.25 9.75a2.5 2.5 0 1 1 5 0 2.5 2.5 0 0 1-5 0z"
                />
              </svg>
            </div>

            {isDropdownOpen && (
              <div className="absolute top-10 right-10 w-30 bg-white rounded-xl shadow-xl z-20">
                <div className="block px-4 py-2 text-sm text-gray-700 rounded-xl hover:bg-gray-100 cursor-pointer" onClick={handleSignOut}>Sign out</div>
              </div>
            )}

          </div>
        </div>
      </div>





    </div>
  )
}
export default Header;