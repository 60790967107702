import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchiProxyData } from '../../../services/proxySlice';
import axios from 'axios';
import moment from 'moment';
import axioscustome from '../../../services/axios';

function IproxyControl() {
    const dispatch = useDispatch();
    const iProxyData = useSelector(state => state.proxy.iProxyData);
    const loading = useSelector(state => state.proxy.loading);
    const error = useSelector(state => state.proxy.error);


    

    const [buttonColors, setButtonColors] = useState({});
    const [currentIps, setCurrentIps] = useState({});
    const [lastChangeTimes, setLastChangeTimes] = useState({});


    const [clickedPorts, setClickedPorts] = useState([]);

  
    const fetchCurrentIps = async () => {
        const url = "https://iproxy.shop/api/proxyAll.php?key=a91cf5f94b410122d3a1e61209593faa4d8d55d11454a5db99624fa44379a1a758c7e39b9c921faf04492064cb8e3f59034dab9c6c3d8fd77d8717f6ea0a90da&rip=1";
        try {
            const response = await axios.get(url);
            const lines = response.data.split('\n');
            const ipMap = {};
            lines.forEach(line => {
                const parts = line.split(':');
                if (parts.length === 3) {
                    const proxyPort = parts[1];
                    const currentIp = parts[2];
                    ipMap[proxyPort] = currentIp;
                }
            });
            return ipMap;
        } catch (error) {
            console.error('Error fetching IPs:', error);
            return {};
        }
    };

    const fetchAndSetCurrentIps = useCallback(async () => {
        const ips = await fetchCurrentIps();
        setCurrentIps(ips);
    }, []);

    const formatLastChangeTime = (time) => {
        return time ? moment(time).fromNow() : 'Not available';
    };

    useEffect(() => {
        dispatch(fetchiProxyData());
        fetchAndSetCurrentIps();
        const interval = setInterval(() => {
            fetchAndSetCurrentIps();
        }, 30000);
        return () => clearInterval(interval);
    }, [dispatch, fetchAndSetCurrentIps]);

    const handleChangeIPClick = useCallback(async (proxyRotating, proxyId) => {
        const url = `https://iproxy.shop/api/${proxyRotating}`;
        try {
            const response = await axios.get(url);
            const { status } = response.data;
            setButtonColors(prevColors => ({ ...prevColors, [proxyId]: status === "ok" ? "green" : "red" }));
            if (status === "ok") {
                const currentTime = new Date().toISOString();
                const updateUrl = `/api/v1/iproxy/${proxyId}/`;
                await axioscustome.put(updateUrl, { proxy_lastchangeip: currentTime });
                setLastChangeTimes(prevTimes => ({ ...prevTimes, [proxyId]: currentTime }));
            }
            setTimeout(() => {
                setButtonColors(prevColors => ({ ...prevColors, [proxyId]: "default" }));
            }, 5000);
        } catch (error) {
            console.error('Error:', error);
            setButtonColors(prevColors => ({ ...prevColors, [proxyId]: "red" }));
            setTimeout(() => {
                setButtonColors(prevColors => ({ ...prevColors, [proxyId]: "default" }));
            }, 5000);
        }
        setClickedPorts(prevClickedPorts => [...prevClickedPorts, proxyId]);
        fetchAndSetCurrentIps();
    }, [fetchAndSetCurrentIps]);

    // Function to return the appropriate SVG based on proxy type
    const getSvgForType = (proxyType) => {
        if (proxyType === 'lte') {
            // Return SVG for LTE type
            return <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlSpace="preserve"
                width={16}
                height={16}
            >
                <title>{"Layer 1"}</title>
                <g stroke="null">
                    <path d="M6.589.061c-1.77.242-3.422 1.01-4.695 2.185l-.262.249.587.587.588.595.394-.332a7.134 7.134 0 0 1 3.132-1.542c.56-.117 2.074-.131 2.607-.02 1.313.276 2.523.885 3.374 1.694l.214.2.594-.595.595-.594-.277-.263C12.46 1.285 11.02.531 9.576.2 8.71 0 7.46-.056 6.589.061z" />
                    <path d="M7.031 3.47c-.96.124-2.046.622-2.738 1.251l-.228.207.595.595.594.595L5.394 6c.442-.373.968-.65 1.452-.788.366-.104 1.286-.104 1.652 0 .54.152.982.38 1.397.733l.207.173.602-.602.601-.601-.2-.18a6.675 6.675 0 0 0-1.335-.864 5.241 5.241 0 0 0-2.738-.401zM0 11.158v3.422h4.273V12.893H1.694V7.735H0v3.423zM5.158 8.579v.843h1.728V14.58H8.573V9.422h1.729V7.735H5.158v.844zM11.636 7.825a1.195 1.195 0 0 0-.297.263l-.118.173-.014 2.786c-.007 1.908 0 2.835.035 2.939.055.2.214.38.422.49.166.09.18.09 2.413.104l2.247.014v-1.7h-3.43v-.886h2.572V10.321h-2.572v-.885h3.423V7.75H11.816l-.18.076z" />
                </g>
            </svg>;
        } else {
            // Return SVG for other types
            return <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlSpace="preserve"
                width={16}
                height={16}

            >
                <title>{"Layer 1"}</title>
                <g stroke="null">
                    <path d="M7.209 2.518a9.092 9.092 0 0 0-5.677 2.629c-.158.15-.321.332-.368.395-.305.42-.17 1.041.28 1.28a.598.598 0 0 0 .358.081c.275 0 .398-.069.708-.382 1.217-1.23 2.645-1.964 4.324-2.228.503-.075 1.703-.075 2.224.013 1.61.27 3.055 1.023 4.237 2.22.31.315.456.396.72.396.31 0 .573-.17.72-.47.052-.1.064-.17.064-.383 0-.339-.036-.414-.41-.797a9.196 9.196 0 0 0-5.413-2.71c-.439-.057-1.358-.082-1.767-.044z" />
                    <path d="M7.261 5.429A6.489 6.489 0 0 0 3.323 7.33c-.322.333-.398.47-.398.772a.78.78 0 0 0 .07.382c.135.289.37.458.667.483.281.02.41-.037.72-.338.51-.483.796-.697 1.311-.96a4.773 4.773 0 0 1 2.587-.527c1.2.113 2.177.564 3.09 1.437.374.35.485.407.772.389.433-.038.743-.383.743-.847 0-.308-.07-.452-.375-.766a6.54 6.54 0 0 0-3.88-1.913 11.113 11.113 0 0 0-1.369-.013z" />
                    <path d="M7.449 8.196c-.45.056-1.001.226-1.405.445-.427.232-1.018.722-1.158.96a.915.915 0 0 0 .163 1.123c.158.145.34.201.574.188.228-.018.322-.062.556-.282a2.51 2.51 0 0 1 .679-.49 2.321 2.321 0 0 1 2.13.013c.269.138.433.258.702.521.21.195.369.257.632.238.24-.018.374-.087.538-.282.2-.238.252-.514.17-.834-.053-.189-.246-.42-.591-.697-.866-.709-1.931-1.035-2.99-.903zM7.759 11.22c-.129.019-.345.1-.463.176-.17.106-.38.363-.48.577-.081.188-.087.213-.087.527 0 .32.006.345.088.527.216.47.62.753 1.088.753.468 0 .878-.283 1.089-.753a1.398 1.398 0 0 0-.07-1.211 1.237 1.237 0 0 0-.826-.584.791.791 0 0 0-.34-.012z" />
                </g>
            </svg>;
        }
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error loading data: {error.message}</p>;

    return (
          <div className="grid grid-cols-2 gap-5">
            {iProxyData.map(proxy => (
                <div key={proxy.id} className={` bg-white p-4 rounded-lg mb-1 ${              
                    clickedPorts.includes(proxy.id) ? "border border-red-500 drop-shadow-lg border-spacing-1" : ""
                }`}>
                    <label className={`text-sm font-semibold flex gap-2 ${              
            clickedPorts.includes(proxy.id) ? "text-red-500" : "text-gray-600"
        }`}>
            {getSvgForType(proxy.proxy_type)}
            {proxy.proxy_mem}:{proxy.proxy_name}
        </label>
                    <div className="relative mt-2 text-gray-500 flex justify-center items-center">
                        <div className="absolute inset-y-0 left-3 my-auto h-6 flex items-center border-r pr-2">
                            <select className="text-sm outline-none rounded-lg h-full">
                                <option>US</option>
                            </select>
                        </div>
                        <input
                            placeholder={currentIps[proxy.proxy_port] || ''}
                            className="text-xs w-full pl-[4.5rem] pr-3 py-1 appearance-none bg-transparent outline-none border rounded-lg"
                            readOnly
                        />
                      <button
    className={`w-40 h-7 ml-2 px-2 text-white rounded-lg shadow-sm text-xs ${
        buttonColors[proxy.id] === "red" ? "bg-red-500" : 
        buttonColors[proxy.id] === "green" ? "bg-green-500" : "bg-slate-400"
    } hover:bg-blue-600`}
    onClick={() => handleChangeIPClick(proxy.proxy_rotating, proxy.id)}
>
    Change IP
</button>
                    </div>
                    <div className="text-gray-500 text-xs mt-2">
                         {formatLastChangeTime(lastChangeTimes[proxy.id] || proxy.proxy_lastchangeip)}
                    </div>
                </div>
            ))}
        </div>
    );
}

export default IproxyControl;