import React, { useState } from 'react';
import TableSitesManagement from "./managerment/tablesitesmanagerment";
import AddSiteModal from "./managerment/addsitemodal";
import axios from '../../services/axios'
import TableNetworksManagement from './managerment/tablenetworkmanagerment';
import AddNetworkModal from './managerment/addnetworkmodal';


function Managerment() {
    const [isAddSiteModalOpen, setAddSiteModalOpen] = useState(false);
    const [sitesManagerData, setSitesManagerData] = useState([]);
    const [isAddNetworkModalOpen, setAddNetworkModalOpen] = useState(false);
    const [networksManagerData, setNetworksManagerData] = useState([]);


    const openAddSiteModal = () => {
        setAddSiteModalOpen(true);
    };

    const closeAddSiteModal = () => {
        setAddSiteModalOpen(false);
    };

    const openAddNetworkModal = () => {
        setAddNetworkModalOpen(true);
    };

    const closeAddNetworkModal = () => {
        setAddNetworkModalOpen(false);
    };




    const handleAddSite = async (siteData) => {
        try {
            const response = await axios.post('/api/sites-managerment/', siteData);
            setSitesManagerData([...sitesManagerData, response]);
            closeAddSiteModal();
        } catch (error) {
            console.error('Error adding site:', error);
        }
    };

    const handleAddNetwork = async (networkData) => {
        try {
            const response = await axios.post('/api/network-managerment/', networkData);
            setNetworksManagerData([...networksManagerData, response]);
            closeAddNetworkModal();
        } catch (error) {
            console.error('Error adding network:', error);
        }
    };


    return (<div className="mt-12 mt-20 mb-6 grid gap-y-5 gap-x-6 md:grid-cols-2 xl:grid-cols-2 p-1">
        <div className="statistics">
            <div className="pl-5">
                <div className="font-semibold">
                    Quản Lý Site
                </div>
                <div className="text-xs">
                    Sử dụng các hành động thêm/sửa/xóa để thao tác
                </div>
            </div>
            <div className="pl-5 mt-5">
            <button
                className="bg-blue-500 hover:bg-blue-700 text-xs text-white font-bold py-2 px-4 rounded"
                onClick={openAddSiteModal}
            >
                + Add Site
            </button>
            </div>
            <div>
                <div className="charttrend mt-5">

                <TableSitesManagement sitesData={sitesManagerData} />
                </div>
            </div>
            <AddSiteModal
                isOpen={isAddSiteModalOpen}
                onClose={closeAddSiteModal}
                onAddSite={handleAddSite}
            />
        </div>
        <div className="statistics">
            <div className="pl-5">
                <div className="font-semibold">
                    Quản Lý Network
                </div>
                <div className="text-xs">
                    Sử dụng các hành động thêm/sửa/xóa để thao tác
                </div>
            </div>
            <div className="pl-5 mt-5">
                <button
                    className="bg-blue-500 hover:bg-blue-700 text-xs text-white font-bold py-2 px-4 rounded"
                    onClick={openAddNetworkModal}
                >
                    + Add Network
                </button>
            </div>
            <div>
                <div className="charttrend mt-5">
                    <TableNetworksManagement networksData={networksManagerData} />
                </div>
            </div>
            <AddNetworkModal
                isOpen={isAddNetworkModalOpen}
                onClose={closeAddNetworkModal}
                onAddNetwork={handleAddNetwork}
            />
            {/* EditNetworkModal khi cần */}
        </div>

    </div>)
}
export default Managerment;